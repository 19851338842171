/* TransactionStep.css */

.transaction-step {
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.header-transaction {
  padding: 15px 20px;
  background: linear-gradient(90deg, #007bff, #00c851);
  color: #fff !important;
  border-bottom: none;
}

.transaction-step .card-body {
  padding: 20px;
}

.transaction-type-selector {
  display: flex;
  border-bottom: 2px solid #e3e3e3;
  margin-bottom: 20px;
}

.transaction-type-selector .tab {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
  color: #555;
}

.transaction-type-selector .tab.active {
  color: rgba(51, 105, 170, 1);
  border-bottom: 3px solid rgba(51, 105, 170, 1);
}

.mint-type-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f1f1f1 !important;
}

.mint-type-selector .form-label {
  margin-bottom: 0;
  font-weight: bold;
}

.transaction-content {
  margin-top: 20px;
}

.footer-transaction {
  background-color: #f8f9fa;
  border-top: 1px solid #e3e3e3;
  padding: 15px 20px;
  text-align: right;
}
