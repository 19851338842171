/* WalletCreator.css */

/* Limit the maximum width of the container for better readability */
  .container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Use a clean sans-serif font for headings */
  h1, h2, h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  
  /* Card styling for forms and wallet details */
  .card {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-bottom: 10px;
  }
  
  .card-header {
    background-color: #007bff;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 20px;
  }
  
  .card-title {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-footer {
    background-color: #f8f9fa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px 20px;
  }
  
  /* Styling for form elements */
  .form-label {
    font-weight: 500;
  }
  
  .form-control {
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
  
  /* Monospace font for keys and hex strings */
  .text-monospace {
    font-family: "Courier New", Courier, monospace;
    background-color: #f1f1f1;
    padding: 2px 4px;
    border-radius: 4px;
  }
  

  
  /* Center the main header */
  h1.text-center {
    text-align: center;
  }
  
  /* Additional spacing for margins */
  .mt-5 {
    margin-top: 3rem !important;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  