.properties-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  max-height: 80%;
  overflow-y: auto;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.overlay-header-properties {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.properties-tree {
  list-style: none;
  padding-left: 1rem;
}

.properties-tree li {
  margin: 0.3rem 0;
}