.stas789-nfts-feed {
  max-width: 1000px;
  margin: 0 auto;
  font-family: sans-serif;
}

.spinner {
  text-align: center;
  font-size: 1rem;
  padding: 1rem;
  color: #555;
}

.nft-post {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background: #fff;
}

.nft-reply {
  display: flex;
  flex-direction: column;
  background: #fff;
}

.expand-comments {
  color: #1877f2;
  text-align: center;
  background-color: #f5f5f5;
  padding: 5px;
  border-bottom: 1px dashed #ccc;
  border-top: 1px dashed #ccc;
}

.card-body {
  padding: 1rem;
}

.nft-post-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.nft-post-placeholder {
  width: 60px;
  height: 60px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.8rem;
}

.nft-post-content {
  padding: 1rem;
  flex-grow: 1;
}

.nft-post-content .card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.nft-post-content .card-text {
  font-size: 1rem;
  line-height: 1.4;
  color: #333;
}

.nft-post-properties a {
  color: #1877f2;
  text-decoration: none;
  font-size: 0.9rem;
}

.nft-post-properties a:hover {
  text-decoration: underline;
}

.nft-post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f0f2f5;
  padding-top: 0;
  font-size: 0.85rem;
  color: #666;
  padding: 0rem 0.5rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  min-width: 80px;
}

.pagination .btn {
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.pagination .btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.nft-post-image-container {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.nft-post-image-full {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 8px;
}

.nft-post-metadata {
  margin-top: 0.5rem;
}

.nft-post-metadata .badge {
  display: inline-block;
  background: #e4e6eb;
  color: #050505;
  border-radius: 12px;
  padding: 0.3rem 0.7rem;
  font-size: 0.8rem;
  margin-right: 0.3rem;
}

.no-nfts {
  text-align: center;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}

.invite-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.invite-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.invite-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.invite-btn:hover {
  background-color: #0056b3;
}