
/* Overall navigation bar */
.navigation-bar {
  background: var(--primary-bg);
  border-bottom: 3px solid var(--accent-color);
  padding: 5px 15px;
  position: sticky;
  top: 0;
  z-index: 1000;
  max-width: 800px;
  margin: 0 auto;
}

/* Centered container - always keep horizontal layout */
.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 40px;
  position: relative;
}

/* Brand styling */
.nav-brand {
  font-family: var(--font-brand);
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  letter-spacing: 0.5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

/* Links container - always horizontal */
.nav-links {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}

/* Auth container - always horizontal */
.nav-auth {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Nav items styling for links and buttons */
.nav-item,
button.nav-item-button {
  font-family: var(--font-body);
  font-size: 14px;
  color: var(--text-color);
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: background-color 0.2s, border-color 0.2s;
  white-space: nowrap;
  position: relative;
}

.nav-item:hover,
button.nav-item-button:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--primary-bg);
}

/* Auth item styling */
.auth-item {
  font-family: var(--font-body);
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid var(--accent-color);
  background-color: transparent;
  transition: background-color 0.2s, border-color 0.2s;
  white-space: nowrap;
}

.auth-item:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--primary-bg);
}

/* Notification dot styling for messages link */
.notification-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 2px;   /* adjust as needed */
  right: 2px; /* adjust as needed */
}

/* Responsive: Ensure horizontal layout on small screens */
@media (max-width: 600px) {
  .nav-container,
  .nav-links,
  .nav-auth {
    flex-direction: row;
    overflow-x: auto;
  }
}