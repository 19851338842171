.wallet-details {
  background: #fff;
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.wallet-card-container {
  display: flex;
  gap: 20px;
}

/* Left column: QR thumbnail and logout */
.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.qr-thumbnail-container {
  width: 100px;
  height: 100px;
  position: relative;
  cursor: pointer;
  /* Square shape: no border-radius */
}

.zoom-icon {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: rgba(0,0,0,0.6);
  border: none;
  color: #fff;
  padding: 4px 6px;
  border-radius: 50%;
  font-size: 0.9rem;
  cursor: pointer;
}

.left-logout {
  margin-top: 10px;
  width: 100%;
}

/* Right column: Wallet details and actions */
.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details-section p {
  font-size: 0.95rem;
  word-break: break-all;
}

.label {
  font-weight: bold;
  margin-right: 8px;
  color: #555;
}

.value {
  font-family: 'Courier New', Courier, monospace;
  color: #222;
}

.action-buttons {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}



.action-button:hover {
  background: #0056b3;
}

.logout-button {
  background-color: #fff;
  border: 1px solid  #dc3545;
  color: #dc3545;
}

.logout-button:hover {
  background-color: #ffe1e1;
  border: 1px solid  #8d0a17;
  color: #8d0a17;
}

/* QR Overlay styles */
.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.qr-container {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Adjusted close button: position it slightly outside the container */
.close-overlay {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #ff5c5c;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}
