.icon-buttons {
  display: flex;
}

.icon-buttons button {
  color: #555;
  font-size: 0.9rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.7rem 0.5rem;
}

.icon-buttons button:hover {
  color: #1877f2;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  font-size: 1rem;
  padding: 0.3rem;
}

.icon-btn:hover {
  color: #1877f2;
}

button.reaction-btn {
  font-size: 1rem;    /* Updated font-size exclusively for reaction button */
  padding: 0.3rem 0.5rem;
}

.reaction-count {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  vertical-align: middle; /* Changed from 'super' to 'middle' */
}

.footer-right {
  display: flex;
  gap: 0.5rem;
}

.footer-right button {
  color: #555;
  font-size: 0.9rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

.footer-right button:hover {
  color: #1877f2;
}

/* Loader styling for reaction mode - centers the spinner */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
