.apps-page {
  margin: 0px;
}
.page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1.5rem;
}
.app-card {
  background: #efefef;
  border: 1px solid #1250d7;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}
.app-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
.app-cover {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.app-card-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  bottom: -30px;
  right: 15px;
  border: 3px solid #fff;
  object-fit: cover;
  background-color: #fff;
}
.app-card-info {
  padding: 15px 15px 0px;
  text-align: left;
}
.app-card-info h5 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.app-card-category {
  color: #888;
  font-size: 0.9rem;
}
