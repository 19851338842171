.landing-container {
	font-family: var(--font-body);
	text-align: center;
	/* Updated attractive, calming gradient with extra stops for smooth transition */
	background: linear-gradient(135deg, #fd23b9 0%, rgb(45, 106, 232) 50%, #42424e 70%, rgb(114, 63, 169) 100%);
	/* Optional: adding background-size for a subtle animated effect */
	background-size: 200% 200%;
	animation: gradientShift 10s ease infinite;
	min-height: calc(100vh - 95px);
	color: var(--text-color);
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: center;
	padding: 0px;
}

@keyframes gradientShift {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}

.landing-header {
	margin-bottom: 20px;
  	padding: 30px;
}

.landing-header h1 {
	font-family: var(--font-brand);
	font-size: 2.0rem;
	margin-bottom: 0.5rem;
}

.landing-header p {
	font-size: 1.2rem;
	margin-bottom: 1rem;
}

.cta-button {
	background-color: gold;
	border: none;
	padding: 10px 20px;
	font-size: 1rem;
	color: #776207;
	cursor: pointer;
	border-radius: 30px;
	transition: background-color 0.3s;
}

.cta-button:hover {
	filter: brightness(0.9);
}

.landing-hero {
	background: rgba(0, 0, 0, 0.2);
	padding: 10px 20px;
	width: 100%;
}

.landing-hero h2 {
	margin: 0;
	font-size: 1.6rem;
}

.landing-hero p {
	margin: 5px 0 0;
	font-size: 1rem;
}

.landing-features {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-top: 30px;
	flex-wrap: wrap;
}

.feature {
	background: rgba(255, 255, 255, 0.1);
	padding: 20px;
	margin: 10px;
	border-radius: 10px;
	flex: 1;
	min-width: 250px;
}

.feature h2 {
	font-size: 1.6rem;
	margin-bottom: 0.5rem;
}

.feature p {
	font-size: 1rem;
}

.landing-footer {
	margin-top: 30px;
	font-size: 0.9rem;
}

.justify-text {
	text-align: justify;
}

.hero-image {
  -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(192, 156, 156, 0));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(192, 167, 167, 0));
}