.onesat-content {
    padding: 10px 0;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .nft-grid-item {
    text-align: center;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .nft-grid-item img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .nft-text {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 10px;
    background-color: #f7f7f7;
  }
  
  .nft-owner {
    margin-top: 5px;
    font-size: 0.8rem;
    color: #666;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-controls span {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .spinner {
    text-align: center;
    font-size: 1rem;
    color: #555;
  }

.ordinal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.ordinal-item {
  display: block;
  text-decoration: none;
}
.ordinal-thumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}
