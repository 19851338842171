.embedded-post {
  background-color: #f0f2f5;
  border-top: 1px dashed var(--info-color);
  border-bottom: 1px dashed var(--info-color);
  padding: 0.5rem;
}

.parent-post{
  background-color: #d0d9e5;
  padding: 0.5rem;
}

.embedded-post.loading,
.embedded-post.error {
  font-size: 0.9rem;
  color: #555;
}

.embedded-share {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-left: 2px solid var(--info-color);
  border-right: 1px dashed var(--info-color);
}
