.overlay-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.overlay-confirm-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 450px;
    width: 90%;
    text-align: center;
}


.overlay-prompt {
    text-align: justify;
}

.overlay-confirm-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
}

/* Added highlight styling */
.overlay-highlight {
  border: 1px dashed #888;
  background-color: #f0f0f0;
  font-size: 0.8rem;
  color: #555;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
