/* TokenMintOrdinals1satNFT.css */

.token-mint-form,
.token-mint-outcome {
  max-width: 800px;
  margin: 20px auto;
}

.token-mint-outcome .card-header {
  padding: 15px 20px;
  background: linear-gradient(90deg, #007bff, #00c851);
  color: #fff !important;
  border-bottom: none;
}

.outcome-body {
  padding: 20px;
  text-align: center;
}

.outcome-message {
  font-size: 1rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.signed-hex-container {
  margin-top: 15px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 4px;
  font-family: monospace;
  word-break: break-all;
}

.outcome-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.outcome-buttons button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
}

.outcome-buttons .copy-btn {
  flex: 1;
  margin-right: 10px;
}

.outcome-buttons .done-btn {
  flex: 1;
  margin-left: 10px;
  text-align: right;
}

