/* WalletTransactionForm.css */

.wallet-transaction-form {
  margin: 20px auto;
}

.wallet-transaction-form .card-body {
  padding: 20px;
}

.outputs-allocation {
  margin-bottom: 20px;
}

.total-funds {
  font-size: 1rem;
  margin-bottom: 15px;
}

.fee-input {
  margin-bottom: 15px;
}

.fee-input .form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.recipients-section h4 {
  margin-bottom: 10px;
}

.recipient-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.recipient-address,
.recipient-value {
  flex: 1;
}

.remove-btn {
  flex-shrink: 0;
}

.add-btn {
  margin-top: 10px;
}

.summary-section h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.summary-section p {
  font-size: 0.95rem;
  color: #333;
}

.alert {
  margin-top: 15px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset-btn {
  margin-left: 15px;
}

.action-section {
  text-align: center;
}

.submit-btn {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.tx-status {
  margin-top: 15px;
  font-size: 0.95rem;
}

.signed-hex-container {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 4px;
  font-family: monospace;
  word-break: break-all;
}

.copy-btn {
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* Enhanced table header with gradient green to blue */
.table-custom-header {
  background: linear-gradient(to right, #f1f1f1, #f1f1f1); /* Green to blue */
  color: #fff;
  font-weight: bold;
  text-align: left;
}

/* Highlight the fee cell (editable) */
.fee-cell {
  background-color: #99994e; /* Light yellow */
  padding: 5px;
}

/* Style for the note below Summary and Fee */
.note {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
  text-align: left;
}

/* Additional styling for the tables as needed */
.table {
  margin-bottom: 20px;
}

/* Input fields with only a bottom border for a professional look */
.border-bottom-only-field {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
}

.border-bottom-only-filed:focus {
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border-bottom-color: #007bff;
  box-shadow: none;
}