.appedit-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.appedit-title {
    text-align: center;
    margin-bottom: 20px;
}

.appedit-form {
    display: flex;
    flex-direction: column;
}

.appedit-form-group {
    margin-bottom: 16px;
}

.appedit-form-control {
    border-radius: 4px;
    padding: 8px;
}

.appedit-submit-btn {
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    margin-top: 16px;
}

.appedit-alert-info {
    margin-top: 10px;
}
