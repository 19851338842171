.home-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.wallet-info {
  margin-bottom: 20px;
  padding: 12px;
  background-color: #f7f7f7;
  border-left: 4px solid #f3ca12;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wallet-label {
  font-weight: bold;
  color: #333;
  font-size: 0.8rem;
}

.wallet-address {
  font-family: monospace;
  color: #555;
  word-break: break-all;
  font-size: 0.8rem;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.tx-card {
  background: #fafafa;
  border-left: 4px solid #7eb074;
  border-radius: 4px;
  padding: 16px;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.tx-card:hover {
  transform: translateY(-3px);
}

.tx-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font-size: 0.9rem;
}

.tx-label {
  font-weight: 600;
  color: #333;
}

.tx-value {
  color: #555;
  text-align: right;
  word-break: break-all;
}

.loader {
  text-align: center;
  font-size: 1rem;
  color: #666;
}

.no-history, .no-wallet {
  text-align: center;
  font-size: 1rem;
  color: #888;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.page-btn {
  background: #f7f7f7;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s ease;
  font-size: 0.9rem;
}

.page-btn:hover:not(.active) {
  background: #e0e0e0;
}

.page-btn.active {
  background: #f3ca12;
  color: #fff;
}

.next-btn {
  font-weight: bold;
}

.tx-card.pending {
  background: #fff8e1; /* a light pending color */
  border-left: 4px solid #ffa000; /* an orange accent for pending */
}

.btn.active-tab {
  background-color:var(--primary-bg);
  color: #fff;
  border-color: var(--primary-bg);
}

.tabs .btn {
  margin-right: 10px;
}