.app-footer {
  background: #f0f0f0;
  border: 1px solid rgb(215, 215, 215);
  border-bottom: 0;
  color: var(--text-color);
  padding: 5px 15px;
  text-align: center;
  max-width: 800px;
  margin: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

/* New styling: horizontal layout */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links a {
  margin: 0 10px;
  color: var(--primary-bg);
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}