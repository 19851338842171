.holdings {
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .holdings-header {
    padding: 15px 20px;
    background: linear-gradient(90deg, #007bff, #00c851);
    color: #fff !important;
    border-bottom: none;
    text-align: left;
  }
  
  .holdings-body {
    padding: 20px;
  }
  
  /* Token Balances Section */
  .token-balances h4 {
    margin-bottom: 10px;
  }
  
  .balances-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .balances-table th,
  .balances-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .balances-table th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  
  .balances-table img {
    max-width: 40px;
    height: auto;
  }
  
  /* NFT Section */
  .nft-section {
    margin-top: 20px;
  }
  
  .nft-type-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .nft-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    padding: 10px;
    background-color: #fff;
  }
  
  .nft-card img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .nft-name {
    margin-top: 5px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  /* Spinner */
  .spinner {
    text-align: center;
    font-size: 1rem;
    color: #555;
  }
  
  /* Footer */
  .holdings-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #e3e3e3;
    padding: 15px 20px;
    text-align: right;
  }
  

  /* Container for pagination controls */
.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

/* Style for the Prev/Next buttons */
.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #007bff;
}

.pagination-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Style for pagination number buttons */
.pagination-number {
  background-color: transparent;
  border: none;
  padding: 5px 8px;
  margin: 0 3px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #007bff;
  transition: color 0.2s ease;
}

.pagination-number:hover {
  color: #007bff;
}

.pagination-number.active {
  font-weight: bold;
  color: #007bff;
}

/* Style for ellipsis */
.pagination-ellipsis {
  padding: 5px 8px;
  margin: 0 3px;
  font-size: 0.9rem;
  color: #7f8c8d;
}


/* Optional: Additional table styling */
.table {
  margin-bottom: 20px;
}

.spinner {
  text-align: center;
  padding: 10px;
  font-style: italic;
}

/* NFT type selector styling */

.nft-type-tabs {
  display: flex;
  justify-content: center;
}

.nft-tab {
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  background: linear-gradient(to right, #fae5c9, #45897b69); /* Green to blue */
  border-radius: 4px;
}

.nft-tab.active {
  background: linear-gradient(to right, #a26413, #45897b69); /* Green to blue */
  color: #fff;
  font-weight: bold;
}

/* Ensure full-width buttons */
.btn-block {
  display: block;
  width: 100%;
}

/* Add spacing between stacked btn-block buttons */
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.tab-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}