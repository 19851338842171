.overlay-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	animation: fadeIn 0.3s forwards;
	z-index: 1100;
}

.overlay-card {
	background: #fff;
	width: 90%;
	max-width: 750px;
	max-height: 90vh;
	border-radius: 8px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	transform: translateY(-20px);
	animation: slideDown 0.3s forwards;
	position: relative; /* Added to position absolute children */
}

.overlay-header {
	display: flex;
	padding: 1rem;
	border-bottom: 1px solid #eee;
}

.close-btn-fullcard {
	background: linear-gradient(145deg, #FFD700, #B8860B);
	cursor: pointer;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 4px;
	right: 5px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1050;
}

.overlay-body {
}

.asset-item {
}

.asset-loader {
	background: #f0f0f0;
	padding: 1rem;
	text-align: center;
	border-radius: 4px;
}

.text-content {
	white-space: pre-wrap;
    padding: 2rem;
}

/* Animations */
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes slideDown {
	from { transform: translateY(-20px); }
	to { transform: translateY(0); }
}
