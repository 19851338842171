.developer-apps {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.developer-apps h1 {
  text-align: center;
  margin-bottom: 20px;
}

.developer-apps-message {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fce4e4;
  color: #a94442;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  text-align: center;
}

.developer-apps-loading {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
}

.app-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.app-card h3 {
  margin-top: 0;
}

.app-details p {
  margin: 5px 0;
}

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.button-group button {
  padding: 8px 12px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button-group button:hover {
  background-color: #0056b3;
}
