.chat-container {
  display: flex;
  flex-direction: column;
  /* Fixed overall height: nav=60px, footer=65px, chat field=70px, plus safe area */
  margin: 10px auto;
  overflow: hidden;
  min-height: calc(100vh - 60px - 65px - env(safe-area-inset-bottom, 0px));
}

.floating-buttons {
  position: fixed;
  top: 60px; /* below navbar */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.chat-messages {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 1rem;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column; 
    justify-content: flex-end; 
}

.chat-controls {
  position: fixed;
  bottom: calc(45px + env(safe-area-inset-bottom, 0px)); /* Adjust to match the footer height */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  background: #f7f7f7;
  padding: 0px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.clear-chat-link {
  /* Remove fixed positioning */
  background: rgba(255, 255, 255, 0.3);
  color: var(--bs-dark);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  z-index: 100;
}

/* Ensure chat-input-container remains horizontal */
.chat-input-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #eee;
}

.chat-input-qr {
  margin-right: 0.5rem;
  cursor: pointer;
}

.chat-input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  outline: none;
}

.chat-send-button {
  margin-left: 0.5rem;
  padding: 0.75rem 1rem;
  border: none;
  background: var(--primary-bg);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.chat-send-button:disabled {
  background: #aaa;
  cursor: not-allowed;
}

.chat-message {
  margin-bottom: 1rem;
}

.chat-message.sent {
  text-align: right;
}

.chat-message.received {
  text-align: left;
}

.chat-bubble {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  max-width: 75%;
}

.chat-bubble.sent {
  background: #d1ffd1;
}

.chat-bubble.received {
  background: #f5e7d3;
}

.chat-timestamp {
  font-size: 0.7rem;
  color: #777;
}

.no-messages-box {
  margin: 200px auto;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9;
  text-align: center;
  font-size: 1rem;
  color: #999;
  max-width: 80%;
  text-align: justify;
}

.autoscroll-toggle {
  background: rgba(255, 255, 255, 0.3);
  color: var(--bs-dark);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
