.admin-apps-page {
  padding: 2rem 0;
}
.page-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
}
.admin-apps-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}
.admin-apps-table th,
.admin-apps-table td {
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  text-align: left;
}
.admin-apps-table th {
  background-color: #f8f8f8;
  font-weight: bold;
}
.admin-apps-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.admin-apps-table tr:hover {
  background-color: #f1f1f1;
}
.btn {
  font-size: 0.85rem;
  padding: 0.35rem 0.75rem;
}
