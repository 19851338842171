.info-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: var(--text-color);
  font-family: var(--font-body);
}

.info-page h1 {
  font-family: var(--font-brand);
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--primary-bg);
}

.info-page h2 {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #333;
}

.info-page p {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}
