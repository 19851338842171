body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #f7f9fc;
  color: #333;
}

.transfer-nft-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.wallet-header {
  text-align: center;
  margin-bottom: 2rem;
}

.wallet-header h1 {
  font-size: 2.5rem;
  color: #4a90e2;
  margin-bottom: 0.5rem;
}

.wallet-header p {
  font-size: 1.1rem;
  color: #666;
}

.nft-card {
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.08);
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.nft-details {
  flex: 1;
}

.nft-details h2 {
  margin-top: 0;
  color: #333;
}

.nft-preview {
  text-align: center;
  margin-bottom: 1rem;
}

.transfer-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.transfer-form .form-group {
  display: flex;
  flex-direction: column;
}

.transfer-form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.transfer-form .form-control {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.65rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.action-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

.utxo-info {
  background: #eef1f7;
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  font-size: 0.85rem;
}

.redeem-section {
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid #ccc;
  text-align: center;
}

.redeem-section h3 {
  margin-bottom: 0.5rem;
}

.redeem-btn {
  background-color: #F44336;
  color: white;
}



.embedded-post-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
