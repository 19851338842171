.developers-page {
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.developers-menu {
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.developers-menu .menu-item {
  margin-right: 1rem;
  text-decoration: none;
  color: #007bff;
}

.developers-menu .menu-item:hover {
  text-decoration: underline;
}

.developers-content {
  line-height: 1.6;
}

/* Overall wrapper */
.stunning-developers-page {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: #f0f0f0;
  color: #333;
  display: flex;
  flex-direction: column;
}

/* Top Navigation Menu styling */
.stunning-header {
  background: linear-gradient(135deg, #0052cc, #0073e6);
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
}

.top-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top-menu .menu-item {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 12px;
  transition: background 0.3s ease;
}

.top-menu .menu-item:hover,
.top-menu .menu-item.active {
  background: rgba(255,255,255,0.2);
  border-radius: 4px;
}

/* Sidebar Navigation styling (default on small screens: full width) */
.stunning-sidebar {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  width: 100%;  /* Ensured full width on small screens */
  margin-right: 0;
  margin-bottom: 20px;
}

.stunning-sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stunning-sidebar nav ul li {
  margin-bottom: 12px;
}

.stunning-sidebar nav ul li a {
  color: #0073e6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.stunning-sidebar nav ul li a:hover {
  color: #0052cc;
}

/* Main Content styling */
.stunning-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0,0,0,0.1);
  overflow: auto;
}

.section-box {
  margin-bottom: 30px;
  padding: 20px;
  border-left: 4px solid #0073e6;
  background: #f1faff;
  border-radius: 4px;
  /* NEW: Add scroll-margin-top to offset the fixed navigation bar */
  scroll-margin-top: 80px;
}

.section-header {
  color: #0052cc;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

/* Code block styling */
pre {
  background: #1e1e1e;
  color: #dcdcdc;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Links and interactive elements */
a {
  color: #0073e6;
  transition: color 0.2s;
}



/* Responsive layout for larger screens */
@media (min-width: 768px) {
  .stunning-developers-page {
    display: flex;
    flex-direction: row;
  }
  .stunning-sidebar {
    flex: 0 0 220px;
    width: 220px;
    margin-bottom: 0;
    position: sticky;
    top: 80px; /* Updated offset to account for navigation bar covering first 80px */
    align-self: flex-start;
    margin-right: 20px;
  }
  .stunning-content {
    flex: 1;
  }
}
