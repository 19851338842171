
.footer-left,
.footer-right {
  display: flex;
  align-items: center;
}


/* New style: Position the profile icon at the top-right inside the content container */
.profile-icon {
  background: #efefef;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px 6px;
  color: #393832;
  font-size: 1rem;
  z-index: 10;
  text-decoration: none;
}

/* New style: Position the profile icon at the top-right inside the content container */
.info-btn  {
  position: absolute;
  top: 0px;
  right: 7px;
  color: #393832;
  font-size: 1rem;
  z-index: 10;
  text-decoration: none;
}


.profile-icon:hover {
  color: #0056b3;
}


.embedded-reply {
  border-left: 2px solid #d4ab9c;
}


@keyframes expandZoomAndSwirl {
  0% {
    transform: scale(0.9) rotate(-15deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.05) rotate(5deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
.expanded-post {
	animation: expandCardZoom 0.6s ease-in-out;
	/* Additional styling as needed */
}