:root {
  /* Base Colours */
  --primary-bg: rgb(36, 87, 188) ;       /* Dark background */
  --accent-color: #f2d013;     /* Amber accent */
  --text-color: #ffffff;       /* White text */
  --border-color: #888888;

  /* Fonts */
  --font-brand: 'Verdana', sans-serif;
  --font-body: 'Tahoma', sans-serif;

  /* Status Colours */
  --warning-color: #f1c40f;
  --danger-color: #e74c3c;
  --info-color: #3ba780;
  --success-color: rgb(16, 105, 78);
}

/* Base reset & body styling */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  background-color:white;
  padding-bottom: env(safe-area-inset-bottom, 0px);
}

/* Central container for all main content */
.main-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #f0f0f0;
  padding: 0px; 
  border: 1px solid #ccc;
  border-top: 0px;
  min-height: calc(100vh - 100px);
}

@media (max-width: 810px) {
  .main-container {
    border: 0px;
  }
}

/* Ensure that elements like sections, etc. use the container */
section,
.main-content,
.auth-landing {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;
}


.btn {
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(74,144,226,0.4);
}


.btn-outline-primary {
  --bs-btn-color: var(--primary-bg);
  --bs-btn-border-color: var(--primary-bg);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary-bg);
  --bs-btn-hover-border-color: var(--primary-bg);
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary-bg);
  --bs-btn-active-border-color: var(--primary-bg);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary-bg);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary-bg);
  --bs-gradient: none;
}

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: var(--warning-color);
  --bs-btn-border-color: color-mix(in srgb, var(--warning-colour) 80%, black 20%);
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e2b70b;
  --bs-btn-hover-border-color: color-mix(in srgb, var(--warning-colour) 50%, black 50%);
  --bs-btn-focus-shadow-rgb: 217,164,6;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: color-mix(in srgb, var(--warning-colour) 50%, black 50%);
  --bs-btn-active-border-color: color-mix(in srgb, var(--warning-colour) 50%, black 50%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: var(--warning-color);
  --bs-btn-disabled-border-color: var(--warning-color);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary-bg);
  --bs-btn-border-color: var(--primary-bg);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: color-mix(in srgb, var(--primary-bg) 80%, black 20%);
  --bs-btn-hover-border-color: color-mix(in srgb, var(--primary-bg) 50%, black 50%);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: color-mix(in srgb, var(--primary-bg) 50%, black 50%);
  --bs-btn-active-border-color: var(--primary-bg);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary-bg);
  --bs-btn-disabled-border-color: var(--primary-bg);
}


.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--info-color);
  --bs-btn-border-color: var(--info-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: color-mix(in srgb, var(--info-color) 80%, black 20%);
  --bs-btn-hover-border-color: color-mix(in srgb, var(--info-color) 50%, black 50%);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: color-mix(in srgb, var(--info-color) 80%, black 20%);
  --bs-btn-active-border-color: var(--info-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--info-color);
  --bs-btn-disabled-border-color: var(--info-color);
}

.app-footer {
  padding-bottom: env(safe-area-inset-bottom); /* Ensure footer respects iOS safe area */
  padding-bottom: calc(env(safe-area-inset-bottom, 0px) + 5px);
}