.app-details {
}
.app-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.app-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.app-icon-large {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid white;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}
.app-category {
  font-size: 1rem;
  color: #666;
}
.app-cover-wrapper {
  margin: 1rem 0;
}
.app-cover {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 0px;
}
.app-iframe {
  margin-top: 1rem;
}
.app-details-page {
  padding: 2rem 0;
  text-align: center;
}
.app-info-card {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 2rem;
}
.app-details-card {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
}
.card-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1.5rem;
}
.card-body {
  flex: 1;
  text-align: left;
}
.card-title {
  font-size: 1.75rem;
  margin: 0;
}
.card-category {
  font-size: 1rem;
  color: #666;
}
.card-text h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.card-category {
  font-size: 1rem;
  color: #666;
}
.app-hero {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 1rem;
}
.hero-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  object-fit: cover;
  margin-right: 1rem;
}
.hero-text h1 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}
.hero-category {
  color: #ccc;
  font-size: 1rem;
}
.app-description {
  margin: 1.5rem 0;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
}
.app-iframe-container {
  margin-top: 0px;
  padding: 0px;
  background-color: #fff;
}
.app-iframe {
  width: 100%;
  min-height: 500px;
  border: none;
  margin: 0px;
}
.app-browser-bar {
  display: flex;
  align-items: center;
  background: #f1f3f5;
  padding: 0px;
  border-radius: 0 0 6px 6px;
  border-top: 2px solid #909090;
  color: #95a1a9;
}
.app-browser-bar .app-icon-small {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.app-browser-bar .app-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 0.5rem;
}
.app-browser-bar .app-category {
  font-size: 0.8rem;
  color: #e6e6e6;
}
