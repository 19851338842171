.visitor-profile {
}

.visitor-header {
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.visitor-info {
  margin-top: 10px;
}

.visitor-qr {
  cursor: pointer;
}

.visitor-qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.visitor-qr-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
  max-width: 300px;
}

.visitor-qr-container .close {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #ff5c5c;
  border: none;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 1rem;
  cursor: pointer;
}
