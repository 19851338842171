/* UTXOTable.css */

.utxo-table-card {
  margin: 20px auto;
  max-width: 800px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.utxo-table-card .card-header {
  background-color: #d7ca73;
  border-bottom: 1px solid #a19059;
  padding: 15px;
  color: #2d2303;
}

.utxo-table-card .card-body {
  padding: 20px;
}

.fetch-controls .input-group {
  margin-bottom: 15px;
}

.fetch-controls input.form-control {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.fetch-controls .btn {
  border-radius: 0 4px 4px 0;
}

.no-utxos {
  padding: 20px;
  color: #6c757d;
  font-style: italic;
}

.table-responsive {
  margin-bottom: 15px;
}

.table .hash-row {
  background-color: #f1f1f1;
}

.table .hash-text {
  word-break: break-all;
}

.data-row input[type="checkbox"] {
  transform: scale(1.2);
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.status-badge.confirmed {
  background-color: #fff3cd;
  color: #856404;
}

.status-badge.outgoing {
  background-color: #fcb196;
  color: #854004;
}

.status-badge.incoming {
  background-color: #f2f0ad;
  color: #67721c;
}

.pagination-controls {
  margin-top: 10px;
}

.pagination-controls span {
  font-size: 0.9rem;
  color: #555555;
}
