.conversations-container {
  margin: 1rem auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.conversations-header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
.conversations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.conversation-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  border-radius: 8px;
  transition: background-color 0.2s, box-shadow 0.2s;

}
.conversation-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
}
.conversation-item:last-child {
  border-bottom: none;
}
.conversation-link {
  display: flex;
  align-items: flex-start; /* Changed from center to flex-start for top alignment */
  width: 100%;
  text-decoration: none;
  color: inherit;
  
}
.conversation-thumbnail {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.conversation-details {
  flex-grow: 1;
  overflow: hidden;
}
.conversation-address {
  font-size: 0.8rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.conversation-snippet {
  font-size: 1rem;
  color: #463b3b;
  margin-top: 4px;
}
.conversation-item.unread-conversation {
  background-color: #fff8e1; /* light yellow */
  border-left: 4px solid #f3d512;
}

.tabs-header {
  display: flex;
  gap: 1rem;
}

.tabs-header button {
  background: transparent;
  border: none;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  outline: none;
  color: #333;
}

.tabs-header button.active-tab {
  border-bottom: 2px solid var(--primary-bg);
  font-weight: bold;
  color: var(--primary-bg);
}

.tabs-header button:hover {
  color: var(--primary-bg);
}
