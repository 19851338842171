.container {
  max-width: 960px;
}
.alert {
  font-size: 1.1rem;
}

.tabs-container {
  display: flex;
  gap: 5px;
  margin-bottom: 0;
  margin-left: 1rem;
}

.tab-button {
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: none;
  background-color: #f9f9f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover state using --warning-color */
.tab-button:hover {
  background-color: var(--info-color);
  color: white;
}

/* Active state using --primary-bg */
.tab-button.active {
  font-weight: bold;
}

.trending-filter {
  display: flex;
  gap: 10px;
  margin: 1rem;
}

.trending-filter-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.trending-filter-btn:hover {
  color: var(--warning-color);
}

.trending-filter-btn.active {
  color: var(--primary-bg);
}

.trending-header {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
  text-align: center;
}

.trending-filter-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.trending-filter-btn {
  border: none;
  background: #f4eede;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background 0.3s ease, color 0.3s ease;
}

.trending-filter-btn i {
  /* let specific colors be inherited from active state */
}

.trending-filter-btn:hover {
  background: var(--info-color);
  color: white;
}

.trending-filter-btn.active {
  background: var(--primary-bg);
  color: white;
}