.token-mint-stas20 {
  max-width: 500px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.token-mint-stas20 h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.info-icon {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 60px;
}

.mint-button {
  width: 100%;
  background: linear-gradient(90deg, #007bff, #00c851);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.mint-button:hover {
  opacity: 0.9;
}

.alert {
  margin-top: 15px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
  text-align: center;
}


small {
  color: dimgrey;
}